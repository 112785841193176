import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import QuoteIcon from './QuoteIcon'

import styles from './styles.module.scss'

const QuoteSection = () => {
  const images = useStaticQuery(researchForMeQuoteImagesQuery)
  return (
    <div className={styles.quote}>
      <div>
        <QuoteIcon />
      </div>
      <div>
        <p>
          “Medicare pays for much – <strong>but not all</strong> – of the costs for
          covered health care services and supplies. A Medigap policy can help pay the
          remaining health care costs like Copayments, Coinsurance, and Deductibles.”
        </p>
      </div>
      <div>
        <Image fixed={images.medicareGov.childImageSharp.fixed} alt=" " />
      </div>
    </div>
  )
}

const researchForMeQuoteImagesQuery = graphql`
  query researchForMeQuoteImagesQuery {
    medicareGov: file(relativePath: { eq: "newLanding/medicare-gov.png" }) {
      childImageSharp {
        fixed(width: 157, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`

export default QuoteSection
