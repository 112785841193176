import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import styles from './styles.module.scss'

const Testimonial = () => {
  const images = useStaticQuery(HOHtestimonialImagesQuery)
  return (
    <div className={styles.testimonial}>
      <div className={styles.imgContainer}>
        <Image fixed={images.testimonial.childImageSharp.fixed} alt=" " />
      </div>
      <div className={styles.right}>
        <p className={styles.p}>
          Our licensed agents eat, drink, and breath Medicare all day, every day. Let us
          know your unique needs and we’ll do the hard work to find the answers and
          coverage you need.
        </p>
        <div>
          <p className={styles.name}>Judith Neilsen</p>
          <p className={styles.title}>Licensed Medicare Agent</p>
        </div>
      </div>
    </div>
  )
}

const HOHtestimonialImagesQuery = graphql`
  query HOHtestimonialImagesQuery {
    testimonial: file(relativePath: { eq: "newLanding/testimonial-avatar.png" }) {
      childImageSharp {
        fixed(width: 208, quality: 90) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`

export default Testimonial
