import React from 'react'
import BallotIcon from 'componentsNew/icons/ballot'
import BalanceScaleIcon from 'componentsNew/icons/balance-scale'
import TrophyIcon from 'componentsNew/icons/trophy'

import styles from './styles.module.scss'

const Benefits = () => (
  <div className={styles.benefits}>
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <div className={styles.infoBox}>
          <div className={styles.iconCircle}>
            <BallotIcon />
          </div>
          <h3>Which Plan Types?</h3>
          <p>
            Medicare Supplement plans cover the leftover 20% that Medicare doesn’t cover.
            There are several different plans to choose from with different coverage
            options
          </p>
        </div>
        <div className={styles.infoBox}>
          <div className={styles.iconCircle}>
            <BalanceScaleIcon />
          </div>
          <h3>What Type of Coverage?</h3>
          <p>
            The short answer, it depends. If you’re on Medicare and want to protect
            yourself from costly medical bills, you should consider additional Medicare
            coverage.
          </p>
        </div>
        <div className={styles.infoBox}>
          <div className={styles.iconCircle}>
            <TrophyIcon />
          </div>
          <h3>Which Insurance Carrier?</h3>
          <p>
            It’s important to remember that Medicare coverage plans are standardized. No
            matter what carrier you choose or how much you pay, the benefits are the same.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Benefits
