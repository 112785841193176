import React from 'react'
import { Helmet } from 'react-helmet'
import ResearchForMeContent from 'componentsNew/ResearchForMe'
import LayoutNew from 'componentsNew/LayoutNew'

const phone = '866-430-8512'

const ResearchForMe = ({ location }) => {
  return (
    <LayoutNew location={location} includeCitySearch>
      <Helmet>
        <title>
          Get Free, Expert Help to Navigate the Medicare Maze | Medicare Companion
        </title>
      </Helmet>
      <ResearchForMeContent phone={phone} />
    </LayoutNew>
  )
}

export default ResearchForMe
