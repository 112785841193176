import React from 'react'

import styles from './styles.module.scss'

const FAQ = () => (
  <div className={styles.FAQ}>
    <div className={styles.container}>
      <h2 className={styles.h2}>Frequently asked questions.</h2>

      <div className={styles.list}>
        <div className={styles.item}>
          <h6>Do I have to pay for your help?</h6>
          <p>
            No, we provide our services at no charge to you. We are happy to answer your
            Medicare-related questions, help you compare your plan options, and decide if
            additional Medicare coverage is right for you.
          </p>
        </div>
        <div className={styles.item}>
          <h6>What if I don't know what Medigap plan I need?</h6>
          <p>
            Our US-based agents can help you assess what's important to you and find an
            affordable plan that gives you peace of mind knowing you are covered.
          </p>
        </div>

        <div className={styles.item}>
          <h6>What if I already have a Medicare coverage?</h6>
          <p>
            If you already have Medicare coverage, but want to change plans or lower your
            premiums, you are in the right place. Simply call us at 1-866-740-2901 (TTY
            Users: 711) or fill out the form at the top or bottom of this page.
          </p>
        </div>

        <div className={styles.item}>
          <h6>How do I get started?</h6>
          <p>
            You can get started by filling out the form on the top or bottom of this page
            or by calling us directly at 1-866-740-2901 (TTY Users: 711). We can help you
            through the entire process, from helping find a plan that works for you to
            submitting your application.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default FAQ
