import React from 'react'

import styles from './styles.module.scss'

const SearchIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svg}
  >
    <path
      d="M16.2812 14.3438L13.1562 11.2188C13 11.0938 12.8125 11 12.625 11H12.125C12.9688 9.90625 13.5 8.53125 13.5 7C13.5 3.4375 10.5625 0.5 7 0.5C3.40625 0.5 0.5 3.4375 0.5 7C0.5 10.5938 3.40625 13.5 7 13.5C8.5 13.5 9.875 13 11 12.125V12.6562C11 12.8438 11.0625 13.0312 11.2188 13.1875L14.3125 16.2812C14.625 16.5938 15.0938 16.5938 15.375 16.2812L16.25 15.4062C16.5625 15.125 16.5625 14.6562 16.2812 14.3438ZM7 11C4.78125 11 3 9.21875 3 7C3 4.8125 4.78125 3 7 3C9.1875 3 11 4.8125 11 7C11 9.21875 9.1875 11 7 11Z"
      fill="currentColor"
    />
  </svg>
)

export default SearchIcon
