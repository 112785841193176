import React from 'react'

import styles from './styles.module.scss'

const BallotIcon = () => (
  <svg
    width="32"
    height="37"
    viewBox="0 0 32 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svg}
  >
    <path
      d="M10.375 6.125H8.125C6.85938 6.125 5.875 7.17969 5.875 8.375V10.625C5.875 11.8906 6.85938 12.875 8.125 12.875H10.375C11.5703 12.875 12.625 11.8906 12.625 10.625V8.375C12.625 7.17969 11.5703 6.125 10.375 6.125ZM10.375 10.625H8.125V8.375H10.375V10.625ZM29.5 0.5H2.5C1.23438 0.5 0.25 1.55469 0.25 2.75V34.25C0.25 35.5156 1.23438 36.5 2.5 36.5H29.5C30.6953 36.5 31.75 35.5156 31.75 34.25V2.75C31.75 1.55469 30.6953 0.5 29.5 0.5ZM29.5 34.25H2.5V2.75H29.5V34.25ZM24.4375 17.375H15.4375C15.0859 17.375 14.875 17.6562 14.875 17.9375V19.0625C14.875 19.4141 15.0859 19.625 15.4375 19.625H24.4375C24.7188 19.625 25 19.4141 25 19.0625V17.9375C25 17.6562 24.7188 17.375 24.4375 17.375ZM10.375 24.125H8.125C6.85938 24.125 5.875 25.1797 5.875 26.375V28.625C5.875 29.8906 6.85938 30.875 8.125 30.875H10.375C11.5703 30.875 12.625 29.8906 12.625 28.625V26.375C12.625 25.1797 11.5703 24.125 10.375 24.125ZM10.375 28.625H8.125V26.375H10.375V28.625ZM24.4375 26.375H15.4375C15.0859 26.375 14.875 26.6562 14.875 26.9375V28.0625C14.875 28.4141 15.0859 28.625 15.4375 28.625H24.4375C24.7188 28.625 25 28.4141 25 28.0625V26.9375C25 26.6562 24.7188 26.375 24.4375 26.375ZM24.4375 8.375H15.4375C15.0859 8.375 14.875 8.65625 14.875 8.9375V10.0625C14.875 10.4141 15.0859 10.625 15.4375 10.625H24.4375C24.7188 10.625 25 10.4141 25 10.0625V8.9375C25 8.65625 24.7188 8.375 24.4375 8.375ZM10.375 15.125H8.125C6.85938 15.125 5.875 16.1797 5.875 17.375V19.625C5.875 20.8906 6.85938 21.875 8.125 21.875H10.375C11.5703 21.875 12.625 20.8906 12.625 19.625V17.375C12.625 16.1797 11.5703 15.125 10.375 15.125ZM10.375 19.625H8.125V17.375H10.375V19.625Z"
      fill="currentColor"
    />
  </svg>
)

export default BallotIcon
