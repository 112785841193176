import React, { useState, useEffect } from 'react'
import debounce from 'lodash/debounce'
import { string } from 'prop-types'

import Newsletter from '../Newsletter'

import Testimonial from '../Testimonial'

import HeaderSticky from './Header'
import Hero from './Hero'
import Benefits from './Benefits'
import TopCarriers from './TopCarriers'
import QuoteSection from './Quote'
import FormSection from './FormSection'
import Footer from './Footer'
import FAQ from './FAQ'

const ResearchForMe = ({ phone }) => {
  const [showStickyHeader, setShowStickyHeader] = useState(false)

  const listener = () => {
    if (document.body.getBoundingClientRect().top < -550) {
      setShowStickyHeader(true)
    } else {
      setShowStickyHeader(false)
    }
  }

  useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'landing',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const debounceWrapper = debounce(listener, 300)
    window.addEventListener('scroll', debounceWrapper)
    return () => {
      window.removeEventListener('scroll', debounceWrapper)
    }
  }, [])

  return (
    <>
      {showStickyHeader && <HeaderSticky phone={phone} />}
      <main>
        <Hero phone={phone} />
        <Benefits />
        <TopCarriers />
        <FormSection phone={phone} />
        <Testimonial />
        <FAQ />
        <QuoteSection />
        <Newsletter />
      </main>
      <Footer phone={phone} />
    </>
  )
}

ResearchForMe.propTypes = {
  phone: string.isRequired,
}

export default ResearchForMe
