import React from 'react'

import styles from './styles.module.scss'

const BalanceScaleIcon = () => (
  <svg
    width="46"
    height="37"
    viewBox="0 0 46 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svg}
  >
    <path
      d="M45.5781 17.9375L37.9141 5.5625C37.7031 5.21094 37.3516 5 37 5C36.5781 5 36.2266 5.21094 36.0156 5.5625L28.3516 17.9375C28.0703 18.2891 27.9297 18.7812 28 19.2734C28.3516 23.9141 32.2188 27.5 37 27.5C41.7109 27.5 45.5781 23.9141 45.9297 19.2734C46 18.7812 45.8594 18.2891 45.5781 17.9375ZM37 25.25C34.0469 25.25 31.5156 23.3516 30.6016 20.75H43.3281C42.4141 23.4219 39.8828 25.25 37 25.25ZM30.6016 18.5L37 8.30469L43.3281 18.5H30.6016ZM38.6875 34.25H24.625V9.35938C26.1719 8.9375 27.3672 7.74219 27.7891 6.19531L38.1953 2.67969C38.5469 2.60938 38.6875 2.25781 38.5469 1.97656L38.1953 0.921875C38.125 0.640625 37.7734 0.5 37.4922 0.570312L27.7891 3.80469C27.2266 1.90625 25.5391 0.5 23.5 0.5C20.9688 0.5 19 2.53906 19 5C19 5.63281 19.0703 6.125 19.2812 6.6875L8.73438 10.2031C8.38281 10.2734 8.24219 10.625 8.38281 10.9062L8.73438 11.9609C8.80469 12.2422 9.15625 12.4531 9.4375 12.3125L20.6875 8.58594C21.1797 8.9375 21.7422 9.21875 22.375 9.35938V35.9375C22.375 36.2891 22.5859 36.5 22.9375 36.5H38.6875C38.9688 36.5 39.25 36.2891 39.25 35.9375V34.8125C39.25 34.5312 38.9688 34.25 38.6875 34.25ZM21.25 5C21.25 3.80469 22.2344 2.75 23.5 2.75C24.6953 2.75 25.75 3.80469 25.75 5C25.75 6.26562 24.6953 7.25 23.5 7.25C22.2344 7.25 21.25 6.26562 21.25 5ZM10.9141 14.5625C10.7031 14.2109 10.3516 14 10 14C9.57812 14 9.22656 14.2109 9.01562 14.5625L1.35156 26.9375C1.07031 27.2891 0.929688 27.7812 1 28.2734C1.35156 32.9141 5.21875 36.5 10 36.5C14.7109 36.5 18.5781 32.9141 18.9297 28.2734C19 27.7812 18.8594 27.2891 18.5781 26.9375L10.9141 14.5625ZM10 34.25C7.04688 34.25 4.51562 32.3516 3.60156 29.75H16.3281C15.4141 32.4219 12.8828 34.25 10 34.25ZM3.60156 27.5L10 17.3047L16.3281 27.5H3.60156Z"
      fill="currentColor"
    />
  </svg>
)

export default BalanceScaleIcon
