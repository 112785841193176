import React from 'react'
import clsx from 'clsx'
import Logo from 'componentsNew/icons/logo'

import Button from '../Button'

import styles from './styles.module.scss'

const HeaderSticky = ({ phone }) => (
  <header className={styles.header}>
    <div className={styles.container}>
      <div>
        <Logo />
      </div>
      <div>
        <a href={`tel:${phone}`} className={clsx('clear-tty', styles.text)}>
          {phone}
        </a>
        <Button size="small">Get Free Research Help</Button>
      </div>
    </div>
  </header>
)

export default HeaderSticky
