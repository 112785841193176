import React from 'react'
import clsx from 'clsx'
import { string, oneOf, oneOfType, node } from 'prop-types'
import SearchIcon from 'componentsNew/icons/search'

import styles from './styles.module.scss'

const scrollIntoView = (id) => {
  const el = document.getElementById(id)
  const yOffset = -88 // height of sticky header
  if (el) {
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
    // el.scrollIntoView({ behavior: 'smooth' })
  }
}

const Button = ({ size, children }) => (
  <button
    className={clsx('clear-tty', styles.button, styles[size])}
    onClick={() => scrollIntoView('research-for-me-form')}
  >
    <span className={styles.icon}>
      <SearchIcon />
    </span>
    {children}
  </button>
)

Button.propTypes = {
  size: oneOf(['small', 'medium', 'large']),
  children: oneOfType([node, string]),
}

Button.defaultProps = {
  size: 'medium',
}

export default Button
