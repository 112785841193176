import React from 'react'

const QuoteIcon = () => (
  <svg
    width="29"
    height="25"
    viewBox="0 0 29 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.701 0.270508H18.2236C17.454 0.270508 16.8293 0.895158 16.8293 1.66482V10.1422C16.8293 10.9119 17.454 11.5365 18.2236 11.5365H22.281C22.2281 13.7563 21.7122 15.5326 20.7362 16.8683C19.9665 17.9224 18.8009 18.7953 17.242 19.4841C16.5253 19.7992 16.2158 20.6469 16.5504 21.3553L17.5543 23.4746C17.8778 24.155 18.6782 24.4534 19.3697 24.155C21.2158 23.3575 22.7747 22.348 24.0462 21.121C25.5967 19.6235 26.6592 17.9336 27.2336 16.0485C27.8081 14.1634 28.0953 11.5923 28.0953 8.32961V1.66482C28.0953 0.895185 27.4706 0.270508 26.701 0.270508ZM3.86224 24.1494C5.68599 23.3547 7.23646 22.3452 8.51644 21.121C10.0809 19.6235 11.1489 17.9392 11.7233 16.068C12.2978 14.1969 12.585 11.6174 12.585 8.32961V1.66482C12.585 0.895158 11.9604 0.270508 11.1907 0.270508H2.7133C1.94364 0.270508 1.319 0.895158 1.319 1.66482V10.1422C1.319 10.9119 1.94364 11.5365 2.7133 11.5365H6.77074C6.71775 13.7563 6.20186 15.5326 5.22584 16.8683C4.45619 17.9224 3.29055 18.7953 1.73171 19.4841C1.01504 19.7992 0.7055 20.6469 1.04013 21.3553L2.04125 23.469C2.36475 24.1494 3.17066 24.4506 3.86224 24.1494Z"
      fill="#D2D6DC"
    />
  </svg>
)

export default QuoteIcon
