import React from 'react'

// import CignaLogo from 'componentsNew/icons/brands/cigna'
import AetnaLogo from 'componentsNew/icons/brands/aetna'
import BCBSLogo from 'componentsNew/icons/brands/bcbs'
import MutualLogo from 'componentsNew/icons/brands/mutual'
// import HumanaLogo from 'componentsNew/icons/brands/humana'

import styles from './styles.module.scss'

const TopCarriers = () => (
  <div className={styles.topCarriers}>
    <div className={styles.container}>
      <h6 className={styles.h6}>
        Uncover your best options across the nation’s top carriers –
      </h6>

      <div className={styles.carriers}>
        {/* <div className={styles.carrier}>
          <CignaLogo />
        </div> */}
        <div className={styles.carrier}>
          <AetnaLogo />
        </div>
        <div className={styles.carrier}>
          <BCBSLogo />
        </div>
        <div className={styles.carrier}>
          <MutualLogo />
        </div>
        {/* <div className={styles.carrier}>
          <HumanaLogo />
        </div> */}
      </div>
    </div>
  </div>
)

export default TopCarriers
