import React from 'react'

import styles from './styles.module.scss'

const TrophyIcon = () => (
  <svg
    width="41"
    height="37"
    viewBox="0 0 41 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.svg}
  >
    <path
      d="M26.2656 10.2734L22.75 9.78125L21.1328 6.61719C20.8516 5.98438 20.0781 5.98438 19.7969 6.61719L18.1797 9.78125L14.6641 10.2734C14.0312 10.4141 13.75 11.1875 14.2422 11.6094L16.7734 14.1406L16.2109 17.6562C16.0703 18.2188 16.7031 18.7109 17.3359 18.4297L20.4297 16.8125L23.5938 18.4297C24.1562 18.7109 24.8594 18.2891 24.7188 17.6562L24.1562 14.1406L26.6875 11.6094C27.1797 11.1875 26.8984 10.4141 26.2656 10.2734ZM22.0469 13.4375L22.3984 15.5469L20.5 14.5625L18.5312 15.5469L18.8828 13.4375L17.3359 11.8906L19.5156 11.6094L20.4297 9.64062L21.4141 11.6094L23.5938 11.8906L22.0469 13.4375ZM31.75 5V1.34375C31.75 0.921875 31.3281 0.5 30.9062 0.5H10.0938C9.60156 0.5 9.25 0.921875 9.25 1.34375V5H1.09375C0.601562 5 0.25 5.42188 0.25 5.84375V10.2031C0.25 14.5625 5.03125 19.625 11.5 20.6094C13.6094 24.8984 16.7031 26.9375 19.375 27.4297V34.25H13.3281C12.2734 34.25 11.5 35.0938 11.5 36.1484C11.5 36.3594 11.6406 36.5 11.9219 36.5H29.0781C29.2891 36.5 29.5 36.3594 29.5 36.1484C29.5 35.0938 28.6562 34.25 27.6719 34.25H21.625V27.4297C24.2266 26.9375 27.3203 24.8984 29.4297 20.6094C35.9688 19.625 40.75 14.5625 40.75 10.2031V5.84375C40.75 5.42188 40.3281 5 39.9062 5H31.75ZM2.5 10.2031V7.25H9.25V8.9375C9.25 12.5938 9.74219 15.6172 10.5156 18.1484C5.66406 16.8828 2.5 13.0156 2.5 10.2031ZM20.5 25.25C15.4375 25.25 11.5 17.9375 11.5 8.9375V2.75H29.5V8.9375C29.5 17.9375 25.5625 25.25 20.5 25.25ZM38.5 10.2031C38.5 13.0156 35.2656 16.8828 30.4141 18.1484C31.2578 15.6172 31.75 12.5938 31.75 8.9375V7.25H38.5V10.2031Z"
      fill="currentColor"
    />
  </svg>
)

export default TrophyIcon
