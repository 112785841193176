import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Image from 'gatsby-image'
import Logo from 'componentsNew/icons/logo'

import Existing from '../../../components/apply/existing'

// import PhoneButton from 'componentsNew/HandsOnHelp/Button'

// import Button from '../Button'

import styles from './styles.module.scss'

const HeaderHero = ({ phone }) => {
  const images = useStaticQuery(researchForMeHeroImagesQuery)
  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.logoContainer}>
            <Logo />
          </div>

          <div className={styles.leftContent}>
            <h1 className={styles.h1}>
              Get free, expert help to navigate the Medicare maze.
            </h1>
            <p className={styles.p}>
              Save time and worry – get expert help from a licensed Medicare agent, with
              zero fees and no commitment.
            </p>

            <div className={styles.cta}>
              <Existing
                toggleApplyLayout
                landing
                type="medicareAlt"
                className="home-inline"
                title="Are you currently enrolled <br />in Medicare parts A & B"
                includeCitySearch
                newResearchDesign
              />
            </div>
            <div className={styles.phoneCta}>
              <span className={styles.ctaTxt}>
                Call to speak with an expert &mdash; <wbr />
              </span>
              <a href={`tel:${phone}`} className="clear-tty">
                {phone}
              </a>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <Image fluid={images.hero.childImageSharp.fluid} alt=" " />
        </div>
      </div>
    </div>
  )
}

const researchForMeHeroImagesQuery = graphql`
  query researchForMeHeroImagesQuery {
    hero: file(relativePath: { eq: "newLanding/r4m-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 656, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default HeaderHero
